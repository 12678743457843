@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  outline: none;
  box-sizing: border-box;
}

:root {
  --theme-bg-color: rgba(16 18 27 / 40%);
  --border-color: rgba(113 119 144 / 25%);
  --theme-color: #f9fafb;
  --inactive-color: rgb(113 119 144 / 78%);
  --body-font: "Poppins", sans-serif;
  --hover-menu-bg: rgba(12 15 25 / 30%);
  --content-title-color: #999ba5;
  --content-bg: rgb(146 151 179 / 13%);
  --button-inactive: rgb(249 250 251 / 55%);
  --dropdown-bg: #21242d;
  --dropdown-hover: rgb(42 46 60);
  --popup-bg: rgb(22 25 37);
  --search-bg: #14162b;
  --overlay-bg: rgba(36, 39, 59, 0.3);
  --scrollbar-bg: rgb(1 2 3 / 40%);
}

.light-mode {
  --theme-bg-color: rgb(255 255 255 / 31%);
  --theme-color: #3c3a3a;
  --inactive-color: #333333;
  --button-inactive: #3c3a3a;
  --search-bg: rgb(255 255 255 / 31%);
  --dropdown-bg: #f7f7f7;
  --overlay-bg: rgb(255 255 255 / 30%);
  --dropdown-hover: rgb(236 236 236);
  --border-color: rgb(255 255 255 / 35%);
  --popup-bg: rgb(255 255 255);
  --hover-menu-bg: rgba(255 255 255 / 35%);
  --scrollbar-bg: rgb(255 253 253 / 57%);
  --content-title-color: --theme-color;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: var(--body-font);
  background-image: url(https://wallpapershome.com/images/wallpapers/macos-big-sur-1280x720-dark-wwdc-2020-22655.jpg);
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 2em; */
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 480px) {
  body {
    padding: 0.8em;
  }
}

.video-bg {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.video-bg video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

img {
  max-width: 100%;
}

.dark-light {
  position: fixed;
  bottom: 50px;
  right: 30px;
  background-color: var(--dropdown-bg);
  box-shadow: -1px 3px 8px -1px rgba(0, 0, 0, 0.2);
  padding: 8px;
  border-radius: 50%;
  z-index: 3;
  cursor: pointer;
}

.dark-light svg {
  width: 24px;
  flex-shrink: 0;
  fill: #ffce45;
  stroke: #ffce45;
  transition: 0.5s;
}

.light-mode .dark-light svg {
  fill: transparent;
  stroke: var(--theme-color);
}

.light-mode .profile-img {
  border: 2px solid var(--theme-bg-color);
}

.light-mode .content-section ul {
  background-color: var(--theme-bg-color);
}

.light-mode .pop-up__title {
  border-color: var(--theme-color);
}

.light-mode .dropdown.is-active ul {
  background-color: rgba(255, 255, 255, 0.94);
}

body.light-mode:before,
body.light-mode .video-bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.45) 100%);
  -webkit-backdrop-filter: saturate(3);
  backdrop-filter: saturate(3);
}

.app {
  background-color: var(--theme-bg-color);
  /* max-width: 1250px;
  max-height: 860px; */
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  /* border-radius: 14px; */
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  font-size: 15px;
  font-weight: 500;
}

.header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 58px;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  padding: 0 30px;
  white-space: nowrap;
  justify-content: space-between;
}

@media screen and (max-width: 480px) {
  .header {
    padding: 0;
  }
}

.header-menu {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.header-menu a {
  padding: 20px 10px;
  text-decoration: none;
  color: var(--inactive-color);
  border-bottom: 2px solid transparent;
  transition: 0.3s;
}

.header-menu span {
  padding: 20px 10px;
  text-decoration: none;
  color: var(--inactive-color);
  border-bottom: 2px solid transparent;
  transition: 0.3s;
  cursor: pointer;
}

/* @media screen and (max-width: 610px) {
  .header-menu a:not(.main-header-link) {
    display: none;
  }
} */

.header-menu a.is-active,
.header-menu a:hover {
  color: var(--theme-color);
  font-weight: 900;
}

.header-menu span.is-active,
.header-menu span:hover {
  color: var(--theme-color);
}

.menu-2 span.is-active,
.menu-2 span:hover {
  color: var(--theme-color);
  border-bottom: 2px solid var(--theme-color);
}

.notify {
  position: relative;
}

.notify:before {
  content: "";
  position: absolute;
  background-color: #3a6df0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  right: 0px;
  top: 10px;
}

/* @media screen and (max-width: 1055px) {
  .notify {
    display: none;
  }
} */

.menu-circle {
  width: 15px;
  height: 15px;
  background-color: #f96057;
  border-radius: 50%;
  box-shadow: 24px 0 0 0 #f8ce52, 48px 0 0 0 #5fcf65;
  margin-right: 195px;
  flex-shrink: 0;
}

@media screen and (max-width: 945px) {
  .menu-circle {
    display: none;
  }
}

.search-bar {
  height: 40px;
  display: flex;
  width: 100%;
  max-width: 400px;
  padding-left: 16px;
  border-radius: 4px;
}

.search-bar input {
  width: 100%;
  height: 100%;
  border: none;
  background-color: var(--search-bg);
  border-radius: 4px;
  font-family: var(--body-font);
  font-size: 15px;
  font-weight: 500;
  padding: 0 20px 0 40px;
  box-shadow: 0 0 0 2px rgba(134, 140, 160, 0.02);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56.966 56.966' fill='%23717790c7'%3e%3cpath d='M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z'/%3e%3c/svg%3e");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: 16px 48%;
  color: var(--theme-color);
}

.search-bar input::-moz-placeholder {
  font-family: var(--body-font);
  color: var(--inactive-color);
  font-size: 15px;
  font-weight: 500;
}

.search-bar input:-ms-input-placeholder {
  font-family: var(--body-font);
  color: var(--inactive-color);
  font-size: 15px;
  font-weight: 500;
}

.search-bar input::placeholder {
  font-family: var(--body-font);
  color: var(--inactive-color);
  font-size: 15px;
  font-weight: 500;
}

.header-profile {
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
  margin-left: auto;
  flex-shrink: 0;
}

.header-profile svg {
  width: 22px;
  color: #f9fafb;
  flex-shrink: 0;
}

.notification {
  position: relative;
}

.notification-number {
  position: absolute;
  background-color: #3a6df0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  right: -6px;
  top: -6px;
}

.notification+svg {
  margin-left: 22px;
}

/* @media screen and (max-width: 945px) {
  .notification+svg {
    display: none;
  }
} */

.profile-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  border: 2px solid var(--theme-color);
  margin-left: 22px;
}

.wide .header-menu,
.wide .header-profile {
  display: none;
}

.wide .search-bar {
  max-width: 600px;
  margin: auto;
  transition: 0.4s;
  box-shadow: 0 0 0 1px var(--border-color);
  padding-left: 0;
}

.wide .menu-circle {
  margin-right: 0;
}

.wrapper {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.left-side {
  flex-basis: 240px;
  border-right: 1px solid var(--border-color);
  padding: 26px;
  overflow: auto;
  flex-shrink: 0;
}

@media screen and (max-width: 945px) {
  .left-side {
    display: none;
  }
}

.side-wrapper+.side-wrapper {
  margin-top: 20px;
}

.side-title {
  color: var(--inactive-color);
  margin-bottom: 14px;
}

.side-menu {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.side-menu a {
  text-decoration: none;
  color: var(--theme-color);
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 10px;
  font-size: 14px;
  border-radius: 6px;
  transition: 0.3s;
}

.side-menu a:hover {
  background-color: var(--hover-menu-bg);
}

.side-menu svg {
  width: 16px;
  margin-right: 8px;
}

.updates {
  position: relative;
  top: 0;
  right: 0;
  margin-left: auto;
  width: 18px;
  height: 18px;
  font-size: 11px;
}

.main-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  height: 58px;
  flex-shrink: 0;
  width: 100%;
  justify-content: center;
}

/* .main-header .header-menu {
  margin-left: 150px;
} */

/* @media screen and (max-width: 1055px) {
  .main-header .header-menu {
    margin: auto;
  }
} */

.main-header .header-menu a {
  padding: 20px 10px;
}

.main-header .header-menu span {
  padding: 20px 10px;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.menu-link-main {
  text-decoration: none;
  color: var(--theme-color);
  padding: 0 30px;
}

/* @media screen and (max-width: 1055px) {
  .menu-link-main {
    display: none;
  }
} */

.content-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--theme-color);
  padding: 20px 40px;
  height: 100%;
  overflow: auto;
  background-color: var(--theme-bg-color);
}

@media screen and (max-width: 510px) {
  .content-wrapper {
    padding: 20px;
  }
}

.content-section-profile {
  display: flex;
  flex-direction: column;
}

.content-section-sell-store {
  display: flex;
  flex-direction: column;
}

.apps-card-profile {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% + 20px);
}

.app-card-profile {
  display: flex;
  flex-direction: column;
  width: 160px;
  font-size: 16px;
  background-color: var(--content-bg);
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  padding: 20px;
  transition: 0.4s ease;
  gap: 20px;
  cursor: pointer;
}

.content-wrapper-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-image: url("https://www.transparenttextures.com/patterns/cubes.png"), linear-gradient(to right top, #cf4af3, #e73bd7, #f631bc, #fd31a2, #ff3a8b, #ff4b78, #ff5e68, #ff705c, #ff8c51, #ffaa49, #ffc848, #ffe652);
  border-radius: 14px;
  padding: 20px 40px;
}

@media screen and (max-width: 415px) {
  .content-wrapper-header {
    padding: 20px;
  }
}

.content-wrapper.overlay {
  pointer-events: none;
  transition: 0.3s;
  background-color: var(--overlay-bg);
}

.overlay-app {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: all;
  background-color: rgba(36, 39, 59, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.overlay-app.is-active {
  visibility: visible;
  opacity: 1;
}

.img-content {
  font-weight: 500;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 0;
}

.img-content svg {
  width: 28px;
  margin-right: 14px;
}

.content-text {
  font-weight: 400;
  font-size: 14px;
  margin-top: 16px;
  line-height: 1.7em;
  color: #ebecec;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-wrapper-context {
  max-width: 350px;
}

.content-button {
  background-color: #3a6df0;
  border: none;
  padding: 8px 26px;
  color: #fff;
  border-radius: 20px;
  margin-top: 16px;
  cursor: pointer;
  transition: 0.3s;
  white-space: nowrap;
}

.content-wrapper-img {
  width: 186px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: -25px;
  -o-object-position: center;
  object-position: center;
}

@media screen and (max-width: 570px) {
  .content-wrapper-img {
    width: 110px;
  }
}

.content-section {
  /* margin-top: 30px; */
  display: flex;
  flex-direction: column;
}

.content-section-profile {
  display: flex;
  flex-direction: column;
}

.content-section-title {
  color: var(--content-title-color);
  margin-bottom: 14px;
}

.content-section ul {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  background-color: var(--content-bg);
  padding-left: 0;
  margin: 0;
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  /* cursor: pointer; */
}

.content-section ul li {
  list-style: none;
  padding: 10px 2px;
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  transition: 0.3s;
}

.content-section ul li:hover {
  background-color: var(--theme-bg-color);
}

.content-section ul li:hover:first-child {
  border-radius: 13px 13px 0 0;
}

.content-section ul li:hover:last-child {
  border-radius: 0 0 13px 13px;
}

.content-section ul li+li {
  border-top: 1px solid var(--border-color);
}

.content-section ul svg {
  width: 28px;
  border-radius: 6px;
  margin-right: 16px;
  flex-shrink: 0;
}

.products {
  display: flex;
  align-items: center;
  /* width: 150px; */
}

/* @media screen and (max-width: 480px) {
  .products {
    width: 120px;
  }
} */

.status {
  margin-left: auto;
  width: 140px;
  font-size: 15px;
  position: relative;
}

@media screen and (max-width: 700px) {
  .status {
    display: none;
  }
}

.status-circle {
  width: 6px;
  height: 6px;
  background-color: #396df0;
  position: absolute;
  border-radius: 50%;
  top: 4px;
  left: -20px;
}

.status-circle.green {
  background-color: #3bf083;
}

.status-button {
  font-size: 15px;
  margin-top: 0;
  padding: 6px 24px;
}

@media screen and (max-width: 390px) {
  .status-button {
    padding: 6px 14px;
  }
}

.status-button.open {
  background: none;
  color: var(--button-inactive);
  border: 1px solid var(--button-inactive);
}

.status-button:not(.open):hover {
  color: #fff;
  border-color: #fff;
}

.content-button:not(.open):hover {
  background: #1e59f1;
}

.menu {
  width: 5px;
  height: 5px;
  background-color: var(--button-inactive);
  border-radius: 50%;
  box-shadow: 7px 0 0 0 var(--button-inactive), 14px 0 0 0 var(--button-inactive);
  margin: 0 12px;
}

@media screen and (max-width: 415px) {
  .adobe-product .menu {
    display: none;
  }
}

.dropdown {
  position: relative;
  height: 53px;
  width: 40px;
  top: -24px;
  display: flex;
  left: -5px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.dropdown ul {
  position: absolute;
  background: var(--dropdown-bg);
  height: 110px;
  width: 120px;
  right: 0;
  top: 20px;
  pointer-events: none;
  opacity: 0;
  transform: translatey(10px);
  transition: all 0.4s ease;
}

.dropdown ul li a {
  text-decoration: none;
  color: var(--theme-color);
  font-size: 12px;
}

.dropdown.is-active ul {
  opacity: 1;
  pointer-events: all;
  transform: translatey(25px);
}

.dropdown.is-active ul li:hover {
  background-color: var(--dropdown-hover);
}

.button-wrapper {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  /* width: 187px; */
  /* margin-left: auto; */
}

/* @media screen and (max-width: 480px) {
  .button-wrapper {
    width: auto;
  }
} */

.pop-up {
  position: absolute;
  padding: 30px 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
  z-index: 10;
  background-color: var(--popup-bg);
  width: 500px;
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  white-space: normal;
}

@media screen and (max-width: 570px) {
  .pop-up {
    width: 100%;
  }
}

.pop-up.visible {
  visibility: visible;
  opacity: 1;
}

.pop-up__title {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pop-up__subtitle {
  white-space: normal;
  margin: 20px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
}

.pop-up__subtitle a {
  color: var(--theme-color);
}

.content-button-wrapper .content-button.status-button.open.close {
  width: auto;
}

.content-section .close {
  margin-right: 0;
  width: 24px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.checkbox-wrapper+.checkbox-wrapper {
  margin: 20px 0 40px;
}

.checkbox {
  display: none;
}

.checkbox+label {
  display: flex;
  align-items: center;
}

.checkbox+label:before {
  content: "";
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border: 1px solid var(--theme-color);
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
}

.checkbox:checked+label:before {
  background-color: #3a6df0;
  border-color: #3a6df0;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpath d='M20 6L9 17l-5-5'/%3e%3c/svg%3e");
  background-position: 50%;
  background-size: 12px;
  background-repeat: no-repeat;
}

.content-button-wrapper {
  margin-top: auto;
  margin-left: auto;
}

.content-button-wrapper .open {
  margin-right: 8px;
}

.apps-card {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  gap: 10px;
}

.app-card {
  display: flex;
  flex-direction: column;
  width: calc(33.3% - 20px);
  font-size: 16px;
  background-color: var(--content-bg);
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  padding: 20px;
  cursor: pointer;
  transition: 0.3s ease;
}

.app-card-shops {
  background-color: var(--content-bg);
  border-radius: 14px;
  border: 1px solid var(--theme-bg-color);
  transition: 0.4s ease;
}

.app-card-shops:hover {
  transform: scale(1.02);
  background-color: var(--theme-bg-color);
}

.app-card:hover {
  transform: scale(1.02);
  background-color: var(--theme-bg-color);
}

.app-card svg {
  width: 28px;
  border-radius: 6px;
  margin-right: 12px;
  flex-shrink: 0;
}

/* .app-card+.app-card {
  margin-left: 20px;
} */

.content-section-title-second {
  color: var(--content-title-color);
  margin-bottom: 14px;
}

.content-section-title-second span {
  font-size: 18px;
  background-color: var(--content-bg);
  border-radius: 8px;
}

.app-card span {
  display: flex;
  align-items: center;
}

.app-card__subtext {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6em;
  margin-top: 20px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 20px;
}

.app-card-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 16px;
}

@media screen and (max-width: 1110px) {
  .app-card {
    width: calc(50% - 20px);
  }
/* 
  .app-card:last-child {
    margin-top: 20px;
    margin-left: 0px;
  } */
}

@media screen and (max-width: 565px) {
  .app-card {
    width: calc(100% - 20px);
    margin-top: 20px;
  }

  .app-card+.app-card {
    margin-left: 0;
  }
}

::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-bg);
  border-radius: 10px;
}




























































































































@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

.AuthenticationLoginForm section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  /* background: url("http://codingstella.com/wp-content/uploads/2024/01/download-8-scaled.jpeg") no-repeat; */
  background-position: center;
  background-size: cover;
}

.AuthenticationLoginForm .form-box {
  position: relative;
  width: 400px;
  height: max-content;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  backdrop-filter: blur(15px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.AuthenticationLoginForm h2 {
  font-size: 2em;
  color: #3c3a3a;
  text-align: center;
}

.AuthenticationLoginForm .inputbox {
  position: relative;
  margin: 30px 0;
  width: 310px;
  border-bottom: 2px solid #3c3a3a;
}

.AuthenticationLoginForm .inputbox label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  color: #3c3a3a;
  font-size: 1em;
  pointer-events: none;
  transition: 0.5s;
}

.AuthenticationLoginForm input:focus~label {
  top: -10px;
}

.AuthenticationLoginForm input:valid~label {
  top: -5px;
}

.AuthenticationLoginForm .inputbox input {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  padding: 0 35px 0 5px;
  color: #3c3a3a;
}

.AuthenticationLoginForm .inputbox input:focus {
  border: none;
  outline: none;
}

.AuthenticationLoginForm .inputbox ion-icon {
  position: absolute;
  right: 8px;
  color: #3c3a3a;
  font-size: 1.2em;
  top: 20px;
}

.AuthenticationLoginForm .forget {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 15px;
  font-size: 0.9em;
  color: #3c3a3a;
}

.AuthenticationLoginForm .forget label:nth-child(2) {
  order: 1;
}

.AuthenticationLoginForm .forget label {
  display: flex;
  align-items: center;
}

.AuthenticationLoginForm .forget label input[type="checkbox"] {
  margin-right: 6px;
}

.AuthenticationLoginForm .forget label a {
  color: #3c3a3a;
  text-decoration: none;
}

.AuthenticationLoginForm .forget label a:hover {
  text-decoration: underline;
}

.AuthenticationLoginForm button {
  width: 100%;
  height: 40px;
  border-radius: 40px;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
}

.AuthenticationLoginForm .register {
  font-size: 0.9em;
  color: #3c3a3a;
  text-align: center;
  margin: 25px 0 10px;
}

.AuthenticationLoginForm .register p a {
  text-decoration: none;
  color: #3c3a3a;
  font-weight: 600;
}

.AuthenticationLoginForm .register p a:hover {
  text-decoration: underline;
}

.CommonLoginBox {
  background-color: var(--search-bg);
}
























































